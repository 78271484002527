"use client";

import Error from "next/error";

// Render the default Next.js 404 page when a route
// is requested that doesn't match the middleware and
// therefore doesn't have a locale associated with it.

const NotFound = () => {
	return (
		<html lang="en">
			{/*
				The headless UI Dialog used for the mobile menu, when open, adds `overflow: hidden` style to the html element which breaks the sticky header since it relies on scrolling within it's container.
				Adding the default `overflow: visible` to the body solves the problem by making the body act as a scrollable container for the header.
			*/}
			<body className="overflow-visible">
				<Error statusCode={404} />
			</body>
		</html>
	);
};

export default NotFound;
